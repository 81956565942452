//Import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

//Import Icons
@import "./style.scss";

// React Modal Video
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.slick-dots {
  padding-top: 15px !important;
  li {
    text-decoration: none !important;
    button {
      width: 12px !important;
      height: 4px !important;
      margin: 5px 7px !important;
      background: rgba(255, 135, 0, 0.5) !important;
      border: none !important;
      border-radius: 20px !important;
      padding: 0px !important;
      &:before {
        content: none !important;
      }
    }
  }
}

.slick-dots {
  li.slick-active {
    button {
        background-color: #ff8700 !important;
    }
  }
}

.bg-overlay-hero{
    background-color: #000;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    height: 103%;
    width: 100%;
}

.background-video video {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.background-video{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    object-fit: cover;
}